/* eslint-disable */
import React, { useRef } from "react"
import Title from "../../Title"
import styles from "../../../css/invoice.module.css"
import Utils from "../../../utils"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { navigate } from "gatsby"
import {entropyToMnemonic} from "bip39"

class Invoice extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      orgs: [],
      invoice_details: {usage: {}},
      loaded: false,
      invoices: [],
      active_org: {name: "loading"},
    };
  }
  handleChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
  }
  handlePersistedChange(e) {
    let state = {};
    state[e.target.name] = e.target.value;
    this.setState(state);
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  handleToggle(e) {
    let state = {};
    state[e.target.name] = !this.state[e.target.name];
    this.setState(state)
    if(typeof window !== "undefined") {
      window.localStorage.setItem(e.target.name, JSON.stringify(state[e.target.name]));
    }
  }
  componentDidMount() {
    let org_id = Utils.getQueryVariable(this.props.location, "org")
    fetch(`/api/org/${org_id}/invoices`).then((r) => r.json()).then((invoices) => {
      this.setState({"invoices": invoices.invoices})
      if(invoices.invoices.length > 0) {
        this.setState({"selected_invoice": invoices.invoices[0]})
        this.setInvoice(invoices.invoices[0])
      }
    }).catch((err) => {
      console.log(err);
      this.setState({"invoices": []})
    })
    if(typeof window !== "undefined") {
      this.setState({
        display_mnemonic: JSON.parse(window.localStorage.getItem("display_mnemonic")),
        network: JSON.parse(window.localStorage.getItem("network")) || "eth",
      });
    }
  }
  setInvoice(period) {
    let org_id = Utils.getQueryVariable(this.props.location, "org")
    return fetch(`/api/org/${org_id}/invoice/${period}`).then((r) => r.json()).then((invoice_details) => {
      this.setState({"current_period": period, "invoice_details": invoice_details, "loaded": true});
    })
  }
  selectInvoice(e) {
    this.setState({"invoice_details": {usage: {}}, "loaded": false})
    this.setInvoice(e.target.value)
  }
  updateKeyNameLocal(e) {
    let keys = this.state.keys;
    keys[e.target.dataset.key].name = e.target.value;
    this.setState({keys: keys});
  }
  updateKeyActiveLocal(value) {
    return (e) => {
      let keys = this.state.keys;
      keys[e.target.dataset.key].active = value;
      this.setState({keys: keys});
    }
  }
  render() {
    const handleCopy = (content) => {
      return (e) => {
        e.preventDefault();
        var textField = document.createElement('textarea')
        textField.innerText = content
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
      }
    }
    if(this.state.invoices.length == 0) {
      return (
        <section className={styles.invoice}>
          <span className={styles.modalHeader}><Title title="Invoices" /></span>
          <p>
            No invoices yet. Check back next month.
          </p>
        </section>
      )
    }
    if( this.state.invoice_details.v == 0 ){
      let key_usage = [];
      for(let key in this.state.invoice_details.usage.keys) {
        key_usage.push(<li key={key}>{key}: {this.state.invoice_details.usage.keys[key]}</li>)
      }
      let billing_periods = [];
      for(let billing_period of this.state.invoices) {
        billing_periods.push(<option key={billing_period} value={billing_period}>{billing_period}</option>)
      }
      if(this.state.loaded) {
        return (
          <section className={styles.invoice}>
          <span className={styles.modalHeader}><Title title="Invoices" /></span>
          <p>
          <select onChange={this.selectInvoice.bind(this)}>{billing_periods}</select>
          </p>
          <ul>
          {key_usage}
          <li>Total Requests: {this.state.invoice_details.usage.total}</li>
          <li>Credits Applied: {this.state.invoice_details.usage.credits}</li>
          <li>Cost: ${(this.state.invoice_details.cost/100).toFixed(2)}</li>
          </ul>
          </section>
        )
      }
      return (
        <section className={styles.invoice}>
        <span className={styles.modalHeader}><Title title="Invoices" /></span>
        <p>
        Loading
        </p>
        </section>
      )
    } else if (this.state.invoice_details.v == 1) {
      let key_usage = [];
      let plans = [];
      console.log(this.state.invoice_details)
      if(this.state.loaded) {
        for(let key in this.state.invoice_details.key_data.keys) {
          key_usage.push(<li key={key}>{key}: {this.state.invoice_details.key_data.keys[key]}</li>)
        }
        let billing_periods = [];
        for(let billing_period of this.state.invoices) {
          billing_periods.push(<option key={billing_period} value={billing_period}>{billing_period}</option>)
        }
        let total_requests = 0;
        let total_cost = 0;
        for(let invoice of this.state.invoice_details.invoices) {
          total_requests += invoice.requests;
          total_cost += invoice.cost;
          plans.push(<tr key={invoice.plan}><td>{invoice.plan}</td><td>{invoice.requests.toLocaleString()}</td><td>{invoice.billed_units.toLocaleString()}x {invoice.unit_name}</td><td>${(invoice.cost / 100).toFixed(2)}</td></tr>)
        }
        return (
          <section className={styles.invoice}>
          <span className={styles.modalHeader}><Title title="Invoices" /></span>
          <p>
          <select value={this.state.current_period} onChange={this.selectInvoice.bind(this)}>{billing_periods}</select>
          </p>
          <table>
            <thead>
              <th>Plan</th><th>Requests</th><th>Billed</th><th>Cost</th>
            </thead>
            <tbody>
            {plans}
            <tr><td><b>Total</b></td><td>{total_requests.toLocaleString()}</td><td></td><td>{(total_cost / 100).toFixed(2)}</td></tr>
            </tbody>
          </table>
          <ul>
          {key_usage}
          </ul>
          </section>
        )
      }
      return (
        <section className={styles.invoice}>
        <span className={styles.modalHeader}><Title title="Invoices" /></span>
        <p>
        Loading
        </p>
        </section>
      )
    } else {
      return (
        <section className={styles.invoice}>
        <span className={styles.modalHeader}><Title title="Invoices" /></span>
        <p>
        Loading
        </p>
        </section>
      )
    }
  }
}

export default Invoice
