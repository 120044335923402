/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import DashLayout from "../components/DashLayout"
import StyledHero from "../components/StyledHero"
import Invoice from "../components/Home/Dashboard/Invoice"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
const invoice = ({ data, location }) => {
  return (
    <DashLayout>
      <Helmet>
        <title>Rivet | Invoice</title>
      </Helmet>
      <StyledHero img="none">
        < Invoice location={location} />
      </StyledHero>
    </DashLayout>
  )
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default invoice
